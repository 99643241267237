import Hero from "./Hero";
import About from "./About";
import Trading from "./Trading";
import Revolution from "./Revolution";
import SandwichArbitrage from "./SandwichArbitrage";
import HowMoneyProccess from "./HowMoneyProccess";
import Faq from "./Faq";
import Security from "./Security";
import HomeFooter from "./HomeFooter";


export {Hero, About, Trading, Revolution, SandwichArbitrage, HowMoneyProccess, Faq, Security, HomeFooter }